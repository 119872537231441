<template>
  <div class="w-full">
    <div class="grid grid-cols-1 w-full">
      <BaseInput
        ref="names"
        type="text"
        label="Nombres"
        v-model="customer.first_name"
        :disabled="true"
        v-if="customer.first_name"
      />
      <BaseInput
        ref="last_name"
        type="text"
        label="Apellidos"
        v-model="customer.last_name"
        :disabled="true"
        v-if="customer.last_name"
      />

      <BaseInput
        type="text"
        label="Tipo identificación"
        v-model="customer.identification_type_name"
        :disabled="true"
        v-if="customer.identification_type_name"
      />
      <BaseInput
        ref="nit"
        type="text"
        id="nit"
        label="NIT"
        v-model="customer.identification_number"
        :disabled="true"
        v-if="customer.identification_number"
      />
      <BaseInput
        ref="company_name"
        type="text"
        id="razonSocial"
        label="Razón social"
        v-model="customer.business_name"
        :disabled="true"
        v-if="customer.business_name"
      />
      <BaseInput
        ref="cellphone"
        label="Número de teléfono"
        type="text"
        phoneNumber
        maxlength="15"
        v-model="customer.phone"
        :disabled="true"
        v-if="customer.phone"
        required="true"
      />
      <BaseInput
        type="text"
        label="Correo electrónico"
        v-model="customer.email"
        :disabled="true"
        v-if="customer.email"
      />
      <BaseSelect
        ref="department"
        id="department"
        label="Seleccione un departamento"
        :selectOptions="formOptions.optionsDepartments"
        v-model="customer.departamento_cod"
        :disabled="true"
        v-if="customer.departamento_cod"
      />
      <BaseSelect
        ref="city"
        id="city"
        label="Seleccione un municipio"
        :selectOptions="optionsMunicipalities"
        v-model="customer.municipio_cod"
        :disabled="true"
        v-if="customer.municipio_cod"
      />
      <BaseInput
        ref="address"
        id="address"
        label="Dirección"
        v-model="customer.address"
        :maxlength="50"
        placeholder="Cra. 1 ## 1A-11"
        type="text"
        v-if="customer.address"
        :disabled="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["customer"],
  data() {
    return {
      optionsMunicipalities: [],
    }
  },
  async created() {
    await this.getMunicipios()
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    }
  },
  methods: {
    async getMunicipios() {
      if (this.$props.customer.departamento_cod == "") {
        return;
      }
      let options = await this.$store.dispatch(
        "form/loadMunicipalitiesOptions",
        { department: this.$props.customer.departamento_cod }
      );
      this.optionsMunicipalities = options;
    }
  }
};
</script>

<style></style>
